<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(onAdd)"
      >
        <v-row>
          <v-col>
            <!-- for admin  -->
            <v-card v-if="userType === 1 || userType === 2">
              <v-card-title primary-title>
                <span class="custome-color-note text-h5">{{
                  $t('messages.parttimer_pick')
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <v-row>
                      <v-col
                        class=" align-center d-flex mb-6 pr-0 "
                        cols="3"
                      >
                        <div>
                          <h3>
                            <span class="font-weight-bold">{{
                              $t('messages.employee_id')
                            }}</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <v-row>
                          <v-col
                            cols="6"
                            class="pr-0"
                          >
                            <div class="align-self-center mr-9">
                              <validation-provider
                                v-slot="{ errors }"
                                ref="searchEmployeeId"
                                name="searchEmployeeId"
                                rules="integer"
                              >
                                <v-text-field
                                  v-model="searchEmployeeId"
                                  dense
                                  single-line
                                  outlined
                                  :error-messages="errors[0]"
                                  @keyup.enter="onSearchEmployeeManageId()"
                                />
                              </validation-provider>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row class="d-flex">
                      <v-col cols="3">
                        <v-btn
                          block
                          color="accent"
                          class="text-h4"
                          @click="onSearchEmployeeManageId()"
                        >
                          {{ $t('messages.search') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <span class="font-weight-bold">{{
                      $t('table.headers.manageId')
                    }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="font-weight-bold">{{
                      $t('table.headers.furiganaName')
                    }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="font-weight-bold">
                      {{ $t('table.headers.name') }}</span>
                  </v-col>
                  <v-col cols="2">
                    <span class="font-weight-bold">
                      {{ $t('table.headers.dob') }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    {{ currentEmployee.manageId }}
                  </v-col>
                  <v-col cols="3">
                    {{ currentEmployee.furiganaName }}
                  </v-col>
                  <v-col cols="3">
                    {{ currentEmployee.name }}
                  </v-col>
                  <v-col cols="2">
                    {{ currentEmployee.dob | formatDate }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="userType === 2 || userType === 4">
              <v-card-title primary-title>
                <span class="warning--text text-h5">{{
                  $t('messages.worktime_signup_notice')
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <!-- date type -->
                    <v-row class="offset-3">
                      <v-col
                        v-for="(n, index) in dateRadioCheckBoxOpt"
                        :key="index"
                        cols="3"
                      >
                        <div>
                          <v-radio-group v-model="dateRadioCheckBox">
                            <v-radio
                              :key="n.label"
                              :label="n.label"
                              :value="n"
                            />
                          </v-radio-group>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col
                        class=" align-center d-flex mb-6"
                        cols="3"
                      >
                        <div>
                          <h2>
                            <span class="font-weight-bold">{{
                              $t('messages.date_range')
                            }}</span>
                          </h2>
                        </div>
                      </v-col>
                      <!-- Single Date option selected -->
                      <v-col
                        v-if="dateRadioCheckBox.value === 'SINGLE_DATE'"
                        cols="9"
                      >
                        <v-row>
                          <v-col>
                            <validation-provider
                              v-slot="{ errors }"
                              rules="date"
                            >
                              <v-row>
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="singleDate"
                                    dense
                                    single-line
                                    outlined
                                    autocomplete="off"
                                    :error-messages="errors[0]"
                                  >
                                    <template v-slot:append />
                                  </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                  <v-menu
                                    v-model="singleDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        class="mt-2 mr-3"
                                        left
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-calendar
                                      </v-icon>
                                    </template>
                                    <v-date-picker
                                      v-model="singleDate"
                                      locale="ja-jn"
                                      color="accent"
                                      @input="singleDateMenu = false"
                                    >
                                      <!-- <v-spacer />
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="singleDateMenu = false"
                                        >
                                        {{ $t('messages.cancel') }}
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="saveValueDate(value)"
                                      >
                                        {{ $t('messages.ok') }}
                                      </v-btn> -->
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- Range Date option selected -->
                      <v-col
                        v-else
                        cols="9"
                      >
                        <v-row>
                          <v-col
                            cols="5"
                            class="pr-0"
                          >
                            <div class="align-self-center mr-9">
                              <!-- <v-menu
                                v-model="fromRangeDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="fromDate"
                                    dense
                                    single-line
                                    outlined
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="fromDate"
                                  color="accent"
                                  :max="toDate"
                                  @input="fromRangeDateMenu = false"
                                />
                              </v-menu> -->
                              <validation-provider
                                v-slot="{ errors }"
                                rules="date"
                              >
                                <v-row>
                                  <v-col cols="10 pr-0">
                                    <v-text-field
                                      v-model="fromDate"
                                      dense
                                      single-line
                                      outlined
                                      autocomplete="off"
                                      :error-messages="errors[0]"
                                    >
                                      <template v-slot:append />
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="1">
                                    <v-menu
                                      v-model="fromRangeDateMenu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          class="mt-2 mr-3"
                                          left
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-calendar
                                        </v-icon>
                                      </template>
                                      <v-date-picker
                                        v-model="fromDate"
                                        locale="ja-jn"
                                        color="accent"
                                        @input="fromRangeDateMenu = false"
                                      />
                                    </v-menu>
                                  </v-col>
                                </v-row>
                              </validation-provider>
                            </div>
                          </v-col>
                          <v-col
                            cols="1"
                            class="d-flex justify-center mt-2 pl-0 pr-0"
                          >
                            <span class="mt-1">～</span>
                          </v-col>
                          <v-col
                            offset="1"
                            cols="5"
                            class="pl-0"
                          >
                            <div class="align-self-center mr-9">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="date"
                              >
                                <v-row>
                                  <v-col cols="10 pr-0">
                                    <v-text-field
                                      v-model="toDate"
                                      dense
                                      single-line
                                      outlined
                                      autocomplete="off"
                                      :error-messages="errors[0]"
                                    >
                                      <template v-slot:append />
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="1">
                                    <v-menu
                                      v-model="toRangeDateMenu"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          class="mt-2 mr-3"
                                          left
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          mdi-calendar
                                        </v-icon>
                                      </template>
                                      <v-date-picker
                                        v-model="toDate"
                                        locale="ja-jn"
                                        color="accent"
                                        @input="toRangeDateMenu = false"
                                      />
                                    </v-menu>
                                  </v-col>
                                </v-row>
                              </validation-provider>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6">
                    <v-row>
                      <v-col
                        class=" align-center d-flex mt-1 pr-0 pl-0"
                        cols="3"
                      >
                        <div>
                          <h4>
                            <span class="text-h5 font-weight-bold">{{
                              $t('messages.unavaiable_workDay')
                            }}</span>
                          </h4>
                        </div>
                      </v-col>

                      <v-col cols="9">
                        <v-item-group>
                          <v-row>
                            <v-col
                              v-for="(day, index) in workDays"
                              :key="index"
                              cols="1"
                              class="pr-0 mr-6 "
                            >
                              <v-item>
                                <v-card
                                  :color="day.isActive ? 'accent' : 'white'"
                                  class="d-flex align-center"
                                  dark
                                  height="35"
                                  width="35"
                                  @click="activeDay(index)"
                                >
                                  <v-scroll-y-transition>
                                    <div
                                      class="text-h4 textDay flex-grow-1 text-center"
                                      :class="
                                        day.isActive
                                          ? ''
                                          : 'text--text font-weight-medium'
                                      "
                                    >
                                      {{ day.name }}
                                    </div>
                                  </v-scroll-y-transition>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-item-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col
                        class=" align-center d-flex mb-6 pr-0 "
                        cols="3"
                      >
                        <div>
                          <h2>
                            <span class="font-weight-bold">{{
                              $t('messages.unavailableTime')
                            }}</span>
                          </h2>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <v-row>
                          <v-col
                            cols="5"
                            class="pr-0"
                          >
                            <div class="align-self-center mr-9">
                              <validation-provider
                                v-slot="{ errors }"
                                ref="fromtime"
                                name="fromtimeValidation"
                                rules="checktime"
                                vid="second"
                              >
                                <v-text-field
                                  v-model="fromTime"
                                  dense
                                  single-line
                                  outlined
                                  :error-messages="errors[0]"
                                  placeholder="hh:mm"
                                />
                              </validation-provider>
                            </div>
                          </v-col>
                          <v-col
                            cols="1"
                            class="d-flex justify-center mt-2 pl-0 pr-0"
                          >
                            ～
                          </v-col>
                          <v-col
                            offset="1"
                            cols="5"
                            class="pl-0"
                          >
                            <div class="align-self-center mr-9">
                              <validation-provider
                                v-slot="{ errors }"
                                name="totimeValidation"
                                rules="checktime|fromTimeCheck:@second"
                              >
                                <v-text-field
                                  v-model="toTime"
                                  dense
                                  single-line
                                  outlined
                                  placeholder="hh:mm"
                                  :error-messages="errors[0]"
                                />
                              </validation-provider>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row class="d-flex justify-end">
                      <v-col cols="3">
                        <v-btn
                          block
                          type="submit"
                          color="accent"
                          class="text-h4"
                        >
                          {{ $t('messages.signup') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-card>
              <v-card-title primary-title />
              <v-card-text>
                <v-row align="center">
                  <v-col>
                    <v-sheet
                      tile
                      height="54"
                      class="d-flex"
                    >
                      <v-row align="center">
                        <v-col
                          class="pl-1"
                          cols="3"
                        >
                          <v-select
                            v-model="type"
                            :items="types"
                            item-text="value"
                            item-value="key"
                            dense
                            outlined
                            hide-details
                            class="ma-2"
                          >
                            <template v-slot:label>
                              <div class="font-weight-bold ">
                                {{ $t('messages.viewOption') }}
                              </div>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          class="pl-1"
                          cols="3"
                        >
                          <v-menu
                            ref="menu2"
                            v-model="menuIntervalPicker"
                            :close-on-content-click="false"
                            :nudge-right="20"
                            :return-value.sync="intervalIndex.value"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="intervalIndex.display"
                                name="actualEndAt"
                                v-bind="attrs"
                                autocomplete="off"
                                readonly
                                clearable
                                v-on="on"
                              >
                                <template v-slot:label>
                                  <div class="font-weight-bold">
                                    {{ $t('messages.intervalIndex') }}
                                  </div>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-if="menuIntervalPicker"
                              v-model="intervalIndex.value"
                              full-width
                              :range="intervalIndex.isRange"
                              locale="ja"
                              :show-current="true"
                              :show-week="true"
                              :type="intervalIndex.type"
                              :no-title="true"
                              format="24hr"
                              @click:date="saveInterval"
                              @click:month="saveInterval"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          offset="2"
                          cols="2"
                        >
                          <span class="font-weight-bold text-h4 accent--text">
                            {{
                              $moment(calendar.value).format('YYYY年 - MM月 ')
                            }}</span>
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <v-btn
                            icon
                            class="ma-2"
                            @click="$refs.calendar.prev()"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            class="ma-2"
                            @click="$refs.calendar.next()"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-sheet>
                    <v-sheet
                      class="mt-3"
                      height="600"
                    >
                      <v-calendar
                        ref="calendar"
                        v-model="calendar.value"
                        locale="ja"
                        :interval-count="24"
                        :interval-style="intervalStyle"
                        :weekdays="weekday"
                        :events="events"
                        :event-more-text="$t('messages.detail')"
                        :event-overlap-mode="mode"
                        :event-overlap-threshold="30"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="handleClickEvent"
                        @click:more="onClickMore"
                      >
                        <template v-slot:event="props">
                          <span class="ml-2">{{ props.event.name || '' }}</span>
                        </template>
                      </v-calendar>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions />
            </v-card>
          </v-col>
          <v-col>
            <v-row
              v-for="(item, key, index) in typeToColor"
              :key="index"
            >
              <v-col>
                <v-card :color="item">
                  <v-card-text>
                    <span class="black--text">{{ $t('messages.' + key) }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="userType === 2 || userType === 4"
          class="d-flex justify-end"
        >
          <v-col cols="2">
            <v-btn
              block
              color="accent"
              class="text-h4"
              @click="onSubmit"
            >
              {{ $t('messages.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      <span class="d-flex justify-center">{{ snackbarMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="editEventDialog"
      max-width="600"
    >
      <v-card>
        <validation-observer v-slot="{ handleSubmit }">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(onUpdate)"
          >
            <v-card-title class="text-h5">
              {{ $t('messages.update_unavailable_worktime') }}
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                  class=" align-center d-flex mb-6 pr-0 "
                  cols="3"
                >
                  <div>
                    <span class="font-weight-bold">{{
                      $t('messages.unavailableTime')
                    }}</span>
                  </div>
                </v-col>
                <v-col cols="9">
                  <v-row>
                    <v-col
                      cols="5"
                      class="pr-0"
                    >
                      <div class="align-self-center mr-9">
                        <validation-provider
                          v-slot="{ errors }"
                          ref="fromtime"
                          name="fromtimeValidation"
                          rules="checktime"
                          vid="second"
                        >
                          <v-text-field
                            v-model="selectEventFromTime"
                            dense
                            single-line
                            outlined
                            :error-messages="errors[0]"
                            placeholder="hh:mm"
                          />
                        </validation-provider>
                      </div>
                    </v-col>
                    <v-col
                      cols="1"
                      class="d-flex justify-center mt-2 pl-0 pr-0"
                    >
                      ～
                    </v-col>
                    <v-col
                      offset="1"
                      cols="5"
                      class="pl-0"
                    >
                      <div class="align-self-center mr-9">
                        <validation-provider
                          v-slot="{ errors }"
                          name="totimeValidation"
                          rules="checktime|fromTimeCheck:@second"
                        >
                          <v-text-field
                            v-model="selectEventToTime"
                            dense
                            single-line
                            outlined
                            placeholder="hh:mm"
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                color="warning"
                text
                @click="editEventDialog = false"
              >
                {{ $t('messages.cancel') }}
              </v-btn>
              <v-btn
                color="error"
                text
                :disable="userType !== 4"
                @click="deleteEvent"
              >
                {{ $t('messages.delete') }}
              </v-btn>
              <v-btn
                :disable="userType !== 4"
                color="green darken-1"
                text
                type="submit"
              >
                {{ $t('messages.save') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="invalidDialog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <span class="text-h4 error--text">{{
                $t('messages.invalid_logs')
              }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              {{ invalidLog[0] ? invalidLog[0].event.start : '' }} -
              {{ invalidLog[0] ? invalidLog[0].event.end : '' }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(item, index) in invalidLog"
            :key="index"
          >
            <v-col>{{ item.messages }}</v-col>
            <v-col v-if="item.secondOverlap.event.start">
              {{
                $moment(
                  item.secondOverlap.event.start,
                  'YYYY-MM-DD HH:mm'
                ).format('MM月 - DD日 HH:mm')
              }}
            </v-col>
            <v-col v-else>
              <span class="font-weight-bold">
                {{
                  $moment(
                    item.firstOverlap.event.start,
                    'YYYY-MM-DD HH:mm'
                  ).format('MM月 - DD日 HH:mm')
                }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- event: {},
          firstOverlap: {
            event: {},
          },
          secondOverlap: { event: {} },
          message: '', -->
    </v-dialog>
    <v-dialog
      v-model="assignShiftlog"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <span class="text-h4 error--text">{{
                $t('messages.invalid_logs')
              }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row
            v-for="(item, index) in dateAssignShifts"
            :key="index"
          >
            <v-col>
              {{
                $moment(
                  item,
                  'YYYY-MM-DD HH:mm'
                ).format('MM月 - DD日')
              }}
            </v-col>
            <v-col>割り当てられました。</v-col>
          </v-row>
          <div class="mt-5 d-flex justify-center">
            管理者に連絡してください。
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="moreDialog"
      scrollable
      max-width="500px"
    >
      <v-card class="pr-3 pl-3">
        <validation-observer v-slot="{ handleSubmit }">
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="handleSubmit(onUpdate)"
          >
            <v-card-title class="text-h5">
              {{ $t('messages.all_event') }}
            </v-card-title>

            <v-card-text>
              <v-row
                v-for="(event, index) in moreEvents"
                :key="index"
              >
                <v-col
                  class=" align-center d-flex mb-6 pr-0 "
                  cols="1"
                >
                  <div>
                    <span class="font-weight-bold">{{ index + 1 }}</span>
                  </div>
                </v-col>
                <v-col cols="11">
                  <v-row>
                    <v-col
                      class="d-flex justify-end"
                      cols="3"
                    >
                      {{
                        $moment(event.start, 'YYYY-MM-DD HH:mm').format('HH:mm')
                      }}
                    </v-col>
                    <v-col cols="1">
                      ~
                    </v-col>
                    <v-col cols="3">
                      {{
                        $moment(event.end, 'YYYY-MM-DD HH:mm').format('HH:mm')
                      }}
                    </v-col>
                    <v-col
                      class="error--text"
                      cols="5"
                    >
                      {{ $t('messages.' + event.type) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    name: 'ParttimerWorkTimeSignUp',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        moreDialog: false,
        moreEvents: [],
        currentEmployee: {
          manageId: '',
          name: '',
          furiganaName: '',
          dob: '',
        },
        invalidDialog: false,
        assignShiftlog: false,
        searchEmployeeId: '',
        userType: '',
        userId: '',
        selectedEvent: {},
        selectedEventIndex: '',
        invalidLog: [],
        dateRadioCheckBox: '',
        editEventDialog: false,
        fromRangeDateMenu: false,
        toRangeDateMenu: false,
        fromDate: '',
        toDate: '',
        singleDate: '',
        singleDateMenu: false,
        dateRadioCheckBoxOpt: [],
        dateStart: moment(new Date().toISOString()).format('yyyy-MM-DD'),
        dateEnd: moment(new Date().toISOString()).format('yyyy-MM-DD'),
        workDays: [],
        workHours: [],
        menuIntervalPicker: false,
        fromTime: '',
        toTime: '',
        selectEventToTime: '',
        selectEventFromTime: '',

        intervalIndex: {
          value: [],
          type: 'date',
          display: '',
          isRange: true,
        },
        type: 'week',
        types: [],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        colors: [
          'blue',
          'indigo',
          'deep-purple',
          'cyan',
          'green',
          'orange',
          'grey darken-1',
        ],
        events: [],
        calendar: {
          today: '',
          start: '',
          value: '',
          end: '',
        },
        typeToColor: {
          unavailableTime: '#F64E60',
          availableTime: '#FFFFFF',
        },

        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        idUnavailableTime: '',
        dateAssignShifts: [],
        parttimeEmployeeId: '',
      }
    },

    computed: {
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'parttimeEmployee',
        'colleges',
      ]),
      unavailableTimes: get('parttimer@unavailbleTimes'),
      employeeDetail: get('parttimer@list'),
      worktimesEvent: get('parttimer@employeesWorktimes'),
    },
    watch: {
      status (value) {
        if (value === 'success') this.showSnackBar('messages.success')
        if (value === 'error') this.showSnackBar('messages.server_error')
      },
      singleDate () {
        this.renderWorksHour()
      },
      fromDate () {
        this.renderWorksHour()
      },
      toDate () {
        this.renderWorksHour()
      },
      searchEmployeeId (value) {
        if (value === '') {
          this.currentEmployee = {
            manageId: '',
            name: '',
            furiganaName: '',
            dob: '',
          }
          this.events = []
        }
      },
      employeeDetail (value) {
        if (value.parttimeEmployees.length === 0) {
          this.showSnackBar('messages.no_results')
        }
        this.currentEmployee = value.parttimeEmployees[0]
        this.getUnavailableTimeByParttimeEmployeeId(value.parttimeEmployees[0].id)
        this.userId = value.parttimeEmployees[0].id
      },

      dateRadioCheckBox (value) {
        this.singleDate = ''
        this.fromDate = ''
        this.toDate = ''
        this.fromTime = ''
        this.toTime = ''
      },

      selectedEvent (value) {
        this.selectEventToTime = moment(
          value.end,
          'YYYY-MM-DD HH:mm',
        ).format('HH:mm')
        if (this.selectEventToTime && this.selectEventToTime === '00:00') {
          this.selectEventToTime = '24:00'
        }
        this.selectEventFromTime = moment(
          value.start,
          'YYYY-MM-DD HH:mm',
        ).format('HH:mm')
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      unavailableTimes (value) {
        this.events = value.map(event => {
          let end = moment.utc(event.endAt).format('YYYY-MM-DD HH:mm')
          if (
            moment.utc(event.startAt).format('YYYY-MM-DD') !== moment.utc(event.endAt).format('YYYY-MM-DD') &&
            moment.utc(event.endAt).format('HH:mm') === '00:00'
          ) {
            end = moment.utc(event.startAt).format('YYYY-MM-DD') + ' 24:00'
          }
          return {
            id: event.id,
            start: moment.utc(event.startAt).format('YYYY-MM-DD HH:mm'),
            end: end,
            type: 'unavailableTime',
          }
        })
      },
      intervalIndex: {
        handler: function (data) {
          if (data.value) {
            let valueDate
            if (Array.isArray(data.value) && data.value.length !== 0) {
              valueDate = moment(data.value[0])
            } else valueDate = moment(data.value)

            this.calendar.value = valueDate.format('YYYY-MM-DD')
          }
          if (!data.display) {
            if (this.intervalIndex.isRange) {
              const startOfWeek = moment()
                .startOf('week')
                .format('YYYY-MM-DD')
              const endOfWeek = moment()
                .endOf('week')
                .format('YYYY-MM-DD')
              this.intervalIndex.value = [startOfWeek, endOfWeek]
              this.intervalIndex.display =
                moment(startOfWeek).format('MM月 DD日') +
                ' から ' +
                moment(endOfWeek).format('MM月 DD日') +
                'まで'
            } else {
              this.intervalIndex.display =
                this.type === 'day'
                  ? moment().format('MM月 DD日')
                  : moment().format('MM月')
              this.intervalIndex.value = moment().format('YYYY-MM-DD')
            }
          }
        },
        deep: true,
      },
      message (value) {
        if (value === 'success') {
          this.$router.push({ path: '/parttimer-list', query: { success: true } })
        } else if (value === 'deleteSuccess') {
          this.showSnackBar('messages.deleteSuccess')
        } else if (value === 'updateSuccess') {
          if (this.userType === 4) {
            this.userId = JSON.parse(
              localStorage.getItem('user'),
            ).detail.typeCorrespondId
            this.getUnavailableTimeByParttimeEmployeeId(this.userId)
          }
          this.showSnackBar('messages.updateSuccess')
        }
      },

      type (value) {
        this.intervalIndex.isRange = false
        this.intervalIndex.display = ''
        if (value === 'month') {
          this.intervalIndex.type = 'month'
          this.intervalIndex.value = ''
        }
        if (value === 'week') {
          this.intervalIndex.type = 'date'
          this.intervalIndex.value = []
          this.intervalIndex.isRange = true
        }
        if (value === 'day') {
          this.intervalIndex.type = 'date'
          this.intervalIndex.value = ''
        }
      },
      fromTime (data) {
        if (data.length === 1 && parseInt(data) > 2) {
          this.fromTime = '0' + data + ':'
        } else if (data.length === 2) return (this.fromTime = data + ':')
      },
      toTime (data) {
        if (data.length === 1 && parseInt(data) > 2) {
          this.toTime = '0' + data + ':'
        } else if (data.length === 2) return (this.toTime = data + ':')
      },
      editEventDialog (value) {
        if (!value) {
          this.idUnavailableTime = ''
        }
      },
      worktimesEvent (value) {
        this.dateAssignShifts = []
        if (value) {
          value.forEach((item) => {
            if (item.plan.length !== 0) {
              this.dateAssignShifts.push(item.date)
            } else {
              item.record.forEach(record => {
                if (record.actualStartAt === null) {
                  this.dateAssignShifts.push(item.date)
                }
              })
            }
          })
        }
      },
      dateAssignShifts () {
        if (this.dateAssignShifts.length !== 0) {
          this.dateAssignShifts.forEach(dateAssignShift => {
            const index = this.events.findIndex((element) => moment.utc(element.start).format('YYYY-MM-DD') === moment.utc(dateAssignShift, 'YYYY-MM-DD').format('YYYY-MM-DD'))
            this.events.splice(index, 1)
          })
          this.assignShiftlog = true
        }
      },
    },
    created () {
      this.initSetting()
    },
    mounted () {
      this.userType = JSON.parse(
        JSON.parse(localStorage.getItem('user')).data,
      ).type

      if (this.userType === 4) {
        this.userId = JSON.parse(
          localStorage.getItem('user'),
        ).detail.typeCorrespondId
        this.getUnavailableTimeByParttimeEmployeeId(this.userId)
      }

      this.dateRadioCheckBoxOpt = [
        {
          label: this.$t('messages.ONE_DATE'),
          value: 'SINGLE_DATE',
        },
        {
          label: this.$t('messages.RANGE_DATE'),
          value: 'RANGE_DATE',
        },
      ]
      this.dateRadioCheckBox = this.dateRadioCheckBoxOpt[0]
      this.types = [
        { key: 'month', value: this.$t('messages.byMonth') },
        { key: 'week', value: this.$t('messages.byWeek') },
        { key: 'day', value: this.$t('messages.today') },
      ]
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('checktime', {
        events: ['blur'],
        validate (value) {
          if (/^[0,1,2]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(value)) {
            const timeSplited = value.split(':')
            if (timeSplited[0] === 24) return false
            return true
          }
          return false
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      extend('fromTimeCheck', {
        // must check for testing
        validate (endtime, target) {
          const starttime = target[0]
          return moment(starttime, 'HH:mm').isBefore(moment(endtime, 'HH:mm'))
        },
        message: this.$t('validator.endTimeInvalid'),
      })
      extend('integer', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
      extend('date', {
        validate (value) {
          return moment(value, 'YYYY-mm-DD').format('YYYY-mm-DD') === value
        },
        message: this.$t('validator.invalid_date'),
      })
    },

    methods: {
      getUnavailableTimeByParttimeEmployeeId (id) {
        if (!id) return
        this.$store.dispatch('parttimer/getUnavailableTimeByParttimeEmployeeId', {
          id: id,
        })
      },
      activeDay (index) {
        this.workDays.forEach((item, id) => {
          if (id === index) {
            item.isActive = _.clone(!item.isActive)
          }
        })
        this.reloadEvents()
      },
      reloadEvents () {
        this.events = this.unavailableTimes.map(event => {
          return {
            id: event.id,
            start: moment.utc(event.startAt).format('YYYY-MM-DD HH:mm'),
            end: moment.utc(event.endAt).format('YYYY-MM-DD HH:mm'),
            type: 'unavailableTime',
          }
        })
      },
      renderWorksHour () {
        this.workDays = []

        if (this.dateRadioCheckBox.value === 'SINGLE_DATE' && this.singleDate) {
          if (!moment(this.singleDate).isValid()) return
          const day = moment(this.singleDate).day()
          this.workDays.push({
            name: this.$t('day.' + day),
            isActive: true,
            code: day,
          })
        } else if (
          this.fromDate &&
          this.toDate &&
          this.dateRadioCheckBox.value === 'RANGE_DATE'
        ) {
          if (
            !moment(this.fromDate).isValid() ||
            !moment(this.toDate).isValid()
          ) {
            return
          }
          const start = moment(this.fromDate)
          const end = moment(this.toDate)
          const dateDiff = end.diff(start, 'days')
          for (let i = 0; i <= dateDiff; i++) {
            if (this.workDays.length === 7) break
            const code = start.day()
            if (!this.workDays.find(o => o.code === code)) {
              this.workDays.push({
                name: this.$t('day.' + start.day()),
                isActive: true,
                code: start.day(),
              })
            }
            start.add(1, 'days')
          }
          this.workDays = [
            ...this.workDays.sort((a, b) =>
              a.code > b.code ? 1 : b.code > a.code ? -1 : 0,
            ),
          ]
        }
      },

      getEventColor (event) {
        const color = this.typeToColor[event.type]
        return color || '#FFFFFF'
      },
      initSetting () {
        const FORMAT_STRING = 'YYYY-MM-DD'
        const today = moment().format(FORMAT_STRING)

        this.calendar.start = moment()
          .startOf('month')
          .format(FORMAT_STRING)
        this.calendar.value = today
      },
      onSubmit () {
        let start = ''
        let end = ''
        let payload = []
        if (this.singleDate) {
          if (this.dateAssignShifts.length === 0) {
            start = this.singleDate + ' ' + this.fromTime
            end = this.singleDate + ' ' + this.toTime
            payload = [
              {
                parttimeEmployeeId: this.userId,
                startAt: moment.utc(start, 'YYYY-MM-DD HH:mm'),
                endAt: moment.utc(end, 'YYYY-MM-DD HH:mm'),
              },
            ]
          }
        } else {
          const startDate = moment(this.fromDate)
          const endDate = moment(this.toDate)
          const dateDiff = endDate.diff(startDate, 'days')
          for (let i = 0; i <= dateDiff; i++) {
            const date = moment(startDate).add(i, 'd')
            const start = date.format('YYYY-MM-DD') + ' ' + this.fromTime
            const end = date.format('YYYY-MM-DD') + ' ' + this.toTime
            payload.push({
              parttimeEmployeeId: this.userId,
              startAt: moment.utc(start, 'YYYY-MM-DD HH:mm'),
              endAt: moment.utc(end, 'YYYY-MM-DD HH:mm'),
            })
          }
        }
        this.$store.dispatch(
          'parttimer/postUnavailableTimeByParttimeEmployeeId',
          payload,
        )
        setTimeout(() => {
          this.getUnavailableTimeByParttimeEmployeeId(this.userId)
        }, 300)
      },
      onAdd () {
        this.getEvents(this.userId)
        if (!this.fromTime || !this.toTime) {
          this.showSnackBar('messages.please_enter_time')
          return
        }
        this.invalidLog = []
        const activeDays = this.workDays
          .filter((day, index) => {
            return day.isActive
          })
          .map(item => {
            return item.code
          })

        if (this.dateRadioCheckBox.value === 'RANGE_DATE') {
          if (!this.fromDate || !this.toDate) {
            this.showSnackBar('messages.please_enter_date')
            return
          }
          const startDate = moment(this.fromDate)
          const endDate = moment(this.toDate)
          const dateDiff = endDate.diff(startDate, 'days')
          for (let i = 0; i <= dateDiff; i++) {
            const date = moment(startDate).add(i, 'd')
            const start = date.format('YYYY-MM-DD') + ' ' + this.fromTime
            const end = date.format('YYYY-MM-DD') + ' ' + this.toTime
            if (activeDays.includes(date.day())) {
              this.pushEvent({
                start: start,
                end: end,
                type: 'unavailableTime', // can change type
              })
            }
          }
        } else if (this.dateRadioCheckBox.value === 'SINGLE_DATE') {
          if (!this.singleDate) {
            this.showSnackBar('messages.please_enter_date')
            return
          }
          const singleDate = moment(this.singleDate)
          const date = moment(singleDate)
          const start = date.format('YYYY-MM-DD') + ' ' + this.fromTime
          const end = date.format('YYYY-MM-DD') + ' ' + this.toTime
          if (activeDays.includes(date.day())) {
            this.pushEvent({
              start: start,
              end: end,
              type: 'unavailableTime', // can change type
            })
          }
        }
        this.showInvalidLog()
      },
      onUpdate () {
        if (!this.selectEventFromTime || !this.selectEventToTime) {
          this.showSnackBar('messages.please_enter_time')
          return
        }
        if (!this.selectedEvent.start || !this.selectedEvent.end) {
          this.showSnackBar('messages.please_enter_date')
          return
        }
        const start =
          moment(this.selectedEvent.start, 'YYYY-MM-DD HH:mm').format(
            'YYYY-MM-DD',
          ) +
          ' ' +
          this.selectEventFromTime
        const end =
          moment(this.selectedEvent.start, 'YYYY-MM-DD HH:mm').format(
            'YYYY-MM-DD',
          )
        const payload = {
          id: this.idUnavailableTime,
          startAt: start,
          endAt: end + ' ' + this.selectEventToTime,
        }
        this.event = payload
        this.$store.dispatch('parttimer/updateUnavailableTimeByParttimeEmployeeId', payload)
        this.editEventDialog = false
      },
      pushEvent (event) {
        const testEvent = event
        const log = {
          event: {},
          firstOverlap: {
            event: {},
          },
          secondOverlap: { event: {} },
          message: '',
        }

        // loop to check if merge is nessasery
        const validateResult = this.checkOverlap(testEvent)

        if (!validateResult.isValid) {
          log.event = testEvent
          log.firstOverlap = validateResult.firstOverlap
          log.secondOverlap = validateResult.secondOverlap
          if (validateResult.secondOverlap.event) {
            log.messages = this.$t('messages.overlap_events')
          } else log.messages = this.$t('messages.overlap_event')
          this.invalidLog.push(log)
          return
        }

        if (validateResult.isMerge) {
          const overlap = this.events[validateResult.firstOverlap.index]
          const overlapStartDate = moment(overlap.start, 'YYYY-MM-DD HH:mm')
          const overlapEndDate = moment(overlap.end, 'YYYY-MM-DD HH:mm')
          const testEventStartDate = moment(testEvent.start, 'YYYY-MM-DD HH:mm')
          const testEventEndDate = moment(testEvent.end, 'YYYY-MM-DD HH:mm')

          const newEvent = {
            start: overlapStartDate.isBefore(testEventStartDate)
              ? overlapStartDate.format('YYYY-MM-DD HH:mm')
              : testEventStartDate.format('YYYY-MM-DD HH:mm'),
            end: overlapEndDate.isBefore(testEventEndDate)
              ? testEventEndDate.format('YYYY-MM-DD HH:mm')
              : overlapEndDate.format('YYYY-MM-DD HH:mm'),
            type: 'unavailableTime',
          }
          this.events.splice(validateResult.firstOverlap.index, 1)
          this.events.push(newEvent)
        } else {
          this.events.push(testEvent)
        }
      },

      checkOverlap (testEvent) {
        const returnData = {
          isMerge: false,
          firstOverlap: {
            event: {},
            index: '',
          },
          secondOverlap: { event: {} },
          isValid: true,
        }
        for (let index = 0; index < this.events.length; index++) {
          const event = this.events[index]
          {
            const dateFormat = 'YYYY-MM-DD'
            // const timeFormat = 'hh:mmm'
            const eventStartDate = moment(event.start, 'YYYY-MM-DD HH:mm')
            const eventEndDate = moment(event.end, 'YYYY-MM-DD HH:mm')
            const tempEventStartDate = moment(testEvent.start, 'YYYY-MM-DD HH:mm')
            const tempEventEndDate = moment(testEvent.end, 'YYYY-MM-DD HH:mm')
            // check equal

            if (
              eventStartDate.isSame(tempEventStartDate) &&
              eventEndDate.isSame(tempEventEndDate)
            ) {
              returnData.firstOverlap.event = event
              returnData.firstOverlap.index = index
              returnData.isValid = false
              return returnData
            }
            if (
              eventStartDate.format(dateFormat) ===
              tempEventStartDate.format(dateFormat)
            ) {
              // is posibile they collide

              if (
                tempEventStartDate.isBetween(eventStartDate, eventEndDate) ||
                tempEventEndDate.isBetween(eventStartDate, eventEndDate) ||
                eventEndDate.isBetween(tempEventStartDate, tempEventEndDate) ||
                eventStartDate.isBetween(tempEventStartDate, tempEventEndDate)
              ) {
                // they collided
                returnData.firstOverlap.event = event
                returnData.firstOverlap.index = index
                returnData.isMerge = true
                const tempEvents = [...this.events]
                tempEvents.splice(index, 1)

                // find if it collide with another event, in array except current
                returnData.secondOverlap = tempEvents.find(evnObj => {
                  return (
                    tempEventStartDate.isBetween(
                      moment(evnObj.start, 'YYYY-MM-DD HH:mm'),
                      moment(evnObj.end, 'YYYY-MM-DD HH:mm'),
                    ) ||
                    tempEventEndDate.isBetween(
                      moment(evnObj.start, 'YYYY-MM-DD HH:mm'),
                      moment(evnObj.end, 'YYYY-MM-DD HH:mm'),
                    ) ||
                    moment(evnObj.start, 'YYYY-MM-DD HH:mm').isBetween(
                      tempEventStartDate,
                      tempEventEndDate,
                    ) ||
                    moment(evnObj.end, 'YYYY-MM-DD HH:mm').isBetween(
                      tempEventStartDate,
                      tempEventEndDate,
                    )
                  )
                })

                returnData.isValid = !returnData.secondOverlap
                // 1 overlap, maybe 2
                break
              }
              // clear no overlap
              break
            }
            // is not is this event date
            continue
          }
        }
        return returnData
      },
      showInvalidLog () {
        if (this.invalidLog.length > 0) this.invalidDialog = true
      },
      handleClickEvent (event) {
        if (this.userType === 3) return
        this.editEventDialog = true
        this.event = event.event
        this.selectedEvent = this.event
        this.idUnavailableTime = event.event.id
      },
      dateFormat (value) {},
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInterval) {
            clearInterval(this.snackbarCloseInterval)
          }
          this.snackbarCloseInterval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      deleteEvent (event) {
        this.events.splice(this.events.indexOf(this.selectedEvent), 1)
        this.editEventDialog = false
        const payload = {
          id: this.idUnavailableTime,
        }
        this.$store.dispatch('parttimer/deleteUnavailableTimeByParttimeEmployeeId', payload)
        setTimeout(() => {
          this.getUnavailableTimeByParttimeEmployeeId(this.userId)
        }, 300)
      },
      onSearchEmployeeManageId () {
        if (!this.searchEmployeeId) {
          this.$refs.searchEmployeeId.applyResult({
            errors: ['このフィールドは必須'],
            valid: false,
            failedRules: {},
          })
          return
        }
        const id = this.searchEmployeeId

        this.searchEmployeeManageId(id)
      },
      searchEmployeeManageId (manageId) {
        if (!manageId) return
        const payload = { keyword: manageId }
        this.$store.dispatch('parttimer/getParttimers', payload)
      },
      searchEmployeeUserId (id) {
        if (!id) return
        const payload = { keyword: id }

        this.$store.dispatch('user/getProfile', payload)
      },
      saveInterval (date) {
        if (this.intervalIndex.isRange) {
          const startOfWeek = moment(date)
            .startOf('week')
            .format('YYYY-MM-DD')
          const endOfWeek = moment(date)
            .endOf('week')
            .format('YYYY-MM-DD')
          this.intervalIndex.value = [startOfWeek, endOfWeek]
          this.intervalIndex.display =
            moment(startOfWeek).format('MM月 DD日') +
            ' から ' +
            moment(endOfWeek).format('MM月 DD日') +
            'まで'
        } else {
          this.intervalIndex.display =
            this.type === 'day'
              ? moment(date).format('MM月 DD日')
              : moment(date).format('MM月')
          this.intervalIndex.value = date
        }
        this.$refs.menu2.save(this.intervalIndex.value)
      },
      intervalStyle (data) {},
      onClickMore (evt) {
        this.moreEvents = this.events.filter(event => {
          return (
            moment(event.start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD') ===
            evt.date
          )
        })
        this.moreEvents = this.moreEvents.sort(function (o1, o2) {
          var momentA = moment(o1.start, 'YYYY-MM-DD HH:mm')
          var momentB = moment(o2.start, 'YYYY-MM-DD HH:mm')
          if (momentA > momentB) return 1
          else if (momentA < momentB) return -1
          else return 0
        })
        this.moreDialog = true
      },
      getEvents (id) {
        if (!id) return
        let startAt = ''
        let endAt = ''
        if (this.dateRadioCheckBox.value === 'RANGE_DATE') {
          startAt = moment(this.fromDate, 'YYYY-MM-DD')
          endAt = moment(this.toDate, 'YYYY-MM-DD')
        } else {
          startAt = moment(this.singleDate, 'YYYY-MM-DD')
          endAt = moment(this.singleDate, 'YYYY-MM-DD')
        }
        const payload = {
          id: this.userId,
          startAt: startAt.format('YYYY-MM-DD'),
          endAt: endAt.format('YYYY-MM-DD'),
        }
        this.$store.dispatch('parttimer/getEmployeeWorktime', payload)
      },
    // checkDate (evt) {
    //   if (
    //     (evt.keyCode >= 48 && evt.keyCode <= 57) ||
    //     (evt.keyCode >= 96 && evt.keyCode <= 105) ||
    //     evt.keyCode === 231
    //   ) {
    //     evt = evt || window.event

    //     if (field.value) {
    //       var size = field.value.length
    //       if (size >= 10) field.value = field.value.slice(0, -1)
    //       if (
    //         (size === 4 && field.value > moment().year()) ||
    //         (size === 7 &&
    //           (Number(field.value.split('-')[1]) > 12 ||
    //             Number(field.value.split('-')[1]) < 1)) ||
    //         (size === 10 &&
    //           (Number(field.value.split('-')[2]) > 31 ||
    //             Number(field.value.split('-')[1]) < 1))
    //       ) {
    //         field.value = ''
    //         // add alert funct
    //         return
    //       }

    //       if (
    //         (size === 4 && field.value < moment().year()) ||
    //         (size === 7 && Number(field.value.split('-')[1]) < 13)
    //       ) {
    //         field.value += '-'
    //       }
    //     }
    //   }
    // },
    },
  }
</script>
<style lang="scss">
  .v-calendar-daily__interval-text {
    font-size: 0.75rem;
  }
  .v-calendar .v-event-timed-container {
    margin-right: 5px;
    margin-left: 5px;
  }
  .theme--light.v-calendar-events .v-event-timed {
    border: none !important;
  }
  .custome-color-note {
    color: #8da0e4
  }
</style>
